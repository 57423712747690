import React, { createContext, ReactNode, useContext, useRef } from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

export interface URLContext {
  queryParams: { [key: string]: string };
  deleteQueryParam: (param: string) => void;
}

const URLContextContext = createContext<URLContext>({
  queryParams: {},
  deleteQueryParam: async () => {},
});

export function useURL() {
  return useContext(URLContextContext);
}

export function URLProvider({ children }: { children: ReactNode }) {
  const location = useLocation();
  const currentUrlRef = useRef<string>(''); // needed to store state between renders
  currentUrlRef.current = location.href;

  const deleteQueryParam = async (param: string) => {
    const currentUrl = new URL(currentUrlRef.current);
    currentUrl.searchParams.delete(param);
    currentUrlRef.current = currentUrl.href;
    await navigate(currentUrl.pathname + currentUrl.search + currentUrl.hash, { replace: true });
  };

  return (
    <URLContextContext.Provider
      value={{
        queryParams: Object.fromEntries(new URLSearchParams(location.search).entries()),
        deleteQueryParam,
      }}
    >
      {children}
    </URLContextContext.Provider>
  );
}
