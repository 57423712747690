// Service to get the current stage of the application
// We are not using the Gatsby environment variable directly in the code
// in case the stage should be determined differently in the future

export type Stage = 'alpha' | 'beta' | 'dev' | 'qa' | 'prod';

const currentStage: Stage = ((process.env.GATSBY_STAGE as Stage) ?? 'dev').toLowerCase() as Stage;

export class StageService {
  static get current(): Stage {
    return currentStage;
  }

  static get isAlpha(): boolean {
    return currentStage === 'alpha';
  }

  static get isBeta(): boolean {
    return currentStage === 'beta';
  }

  static get isDev(): boolean {
    return currentStage === 'dev';
  }

  static get isQa(): boolean {
    return currentStage === 'qa';
  }

  static get isProd(): boolean {
    return currentStage === 'prod';
  }
}
