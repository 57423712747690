import React, { createContext, ReactNode, useContext } from 'react';

export interface BetaContext {
  isBeta: boolean;
}

const BetaContext = createContext<BetaContext>({
  isBeta: false,
});

export function useBeta() {
  return useContext(BetaContext);
}

export function BetaContextProvider({ children }: { children: ReactNode }) {
  return <BetaContext.Provider value={{ isBeta: true }}>{children}</BetaContext.Provider>;
}
