// Enable/disable features here, can be set via env vars if necessary

export const features = {
  byod: true,
  useTestData: false,
  promptGallery: process.env.GATSBY_FEATURE_SHOW_PROMPT_GALLERY === 'true',
  promptSuggestions: process.env.GATSBY_FEATURE_SHOW_PROMPT_GALLERY === 'true',
  systemPrompt: process.env.GATSBY_FEATURE_SHOW_SYSTEM_PROMPT === 'true',
  feedbackLoop: process.env.GATSBY_FEATURE_SHOW_FEEDBACK_LOOP === 'true',
};
