import { Stage, StageService } from './stageService';

export type Flavour = 'beta' | 'prod';

const flavourMap: Record<Stage, Flavour> = {
  alpha: 'beta',
  beta: 'beta',
  dev: 'prod',
  qa: 'prod',
  prod: 'prod',
};

export class AppFlavourService {
  static get current(): Flavour {
    return flavourMap[StageService.current];
  }

  static get isBeta(): boolean {
    return this.current === 'beta';
  }

  static get isProd(): boolean {
    return this.current === 'prod';
  }
}
