import { navigate } from 'gatsby';
import LocalStorage from './localStorage';
import { lastSuccessfulRetryKey } from '../context/auth';

export interface RequestError extends Response {
  errorBody?: any;
}

export class ApiService {
  static setGlobalError: (error: RequestError) => void = () => {};

  static registerGlobalErrorSetter(setGlobalError: (error: RequestError) => void) {
    this.setGlobalError = setGlobalError;
  }

  static createHeaders(token: string) {
    const headers: HeadersInit = {
      Authorization: `Bearer ${token}`,
      'ocp-apim-subscription-key': process.env.GATSBY_OCP_APIM_SUBSCRIPTION_KEY!,
    };

    if (process.env.X_USER_ID) {
      headers['x-user-id'] = process.env.X_USER_ID;
      headers['x-application-id'] = process.env.GATSBY_AUTH_CLIENT_ID!;
    }

    return headers;
  }

  static async request<T>(
    url: string,
    token: string,
    options: RequestInit & { json?: object; raw?: boolean } = {},
  ): Promise<T> {
    options.headers = {
      ...this.createHeaders(token),
      ...(options.json ? { 'Content-Type': 'application/json' } : {}),
      ...(options.headers ?? {}),
    };

    if (options.json) {
      options.body = JSON.stringify(options.json);
    }

    const response = await fetch(url, options).catch(e => e);

    if (response.ok) {
      return options.raw ? response : response.json();
    } else {
      if (response.status === 401) {
        // normally the token should be refreshed automatically, but if this did not work for some reason, we need to trigger a new login as the used package does not expose the token refresh function
        const lastSuccessfulRetry = LocalStorage.get<number>(lastSuccessfulRetryKey) || 0;
        if (lastSuccessfulRetry < Date.now() - 15000) {
          LocalStorage.remove('ROCP_token');
          LocalStorage.remove('ROCP_tokenExpire');
          window.location.reload();
        } else {
          navigate('/401');
        }
      } else if ([403, 429, 502, 504].includes(response.status)) {
        this.setGlobalError(response);
      }
      if (response.json) {
        response.errorBody = await response.json();
      }
      return Promise.reject(response);
    }
  }
}
