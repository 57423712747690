import { ConsentCategory, ConsentCategoryApi, ConsentDTO, UserConsentsDTO } from '../types/consents';
import { ApiService } from './apiService';

function buildUri(path: string): string {
  if (process.env.OVERRIDE_CONSENT_SERVICE_BASE_URI) {
    return `${process.env.OVERRIDE_CONSENT_SERVICE_BASE_URI}${path}`;
  }
  return `${process.env.GATSBY_API_BASE_URI!}/consent/v1${path}`;
}

export default class ConsentApiService {
  static readonly consents: { [consent in ConsentCategory]: { version: string; category: ConsentCategoryApi } } = {
    quiz: {
      version: '1',
      category: 'quiz',
    },
    guidelines: {
      version: '1',
      category: 'consent',
    },
    teaserFreeTrial: {
      version: '1',
      category: 'free-trial-teaser',
    },
  };

  static async getConsentsDto(token: string): Promise<UserConsentsDTO> {
    const response = await ApiService.request<UserConsentsDTO>(buildUri('/consent'), token);
    return this.formatConsents(response);
  }

  static async createConsent(category: ConsentCategoryApi, version: string, token: string): Promise<ConsentDTO> {
    const response = await ApiService.request<ConsentDTO>(buildUri(`/consent/${category}/${version}`), token, {
      method: 'POST',
    });
    return this.formatConsent(response);
  }

  static async getUserProfile(token: string): Promise<any> {
    const response = await ApiService.request<ConsentDTO>(buildUri('/user-profile'), token, {
      method: 'GET',
    });
    return Promise.resolve(response);
  }

  private static formatConsents(consents: UserConsentsDTO): UserConsentsDTO {
    consents.contents = consents.contents.map(this.formatConsent);
    return consents;
  }

  private static formatConsent(consent: ConsentDTO): ConsentDTO {
    return { ...consent, category: consent.category.toLowerCase() as ConsentCategoryApi };
  }
}
