import { GeneratedSupportedSpaceDTO, SpaceDraftApiDTO } from '../types/spaceAdministrationTypes';
import { RawPrompt, RawSpaceDTO, SupportedSpace, SpaceDTO, SpaceProvider, SupportedSpaces } from '../types/spaceConfig';
import { ApiService } from './apiService';
import { features } from '../../global/features';

import { PUBLIC_SPACE } from '../hooks/spaces';
import { LLMId } from '../types/llm';
import { StageService } from './stageService';
import i18nConfig from '../../i18n.config.json';
import { TranslatedString } from '../types/translation';
import { AccessRequestActionDTO, FileDTO, SpaceDraftDTO } from '../types/halo';

export class SpaceAdministrationService {
  // we only enable llms in this list, all others are being flagged as 'coming soon' even if the backend reports them as enabled
  static publicSpaceLlmWhitelist: LLMId[] =
    StageService.isProd || StageService.isQa
      ? ['GPT3', 'GPT4', 'GEMINI', 'PALM2', 'GPT4O_MINI', 'GEMINI_BASIC']
      : StageService.isBeta || StageService.isAlpha
        ? ['GPT3']
        : ['GPT3', 'GPT4', 'GEMINI', 'GPT4O_MINI', 'GEMINI_BASIC'];

  static buildUri(path: string): string {
    if (process.env.OVERRIDE_SPACE_ADMINISTRATION_URI) {
      return `${process.env.OVERRIDE_SPACE_ADMINISTRATION_URI}${path}`;
    }
    return `${process.env.GATSBY_API_BASE_URI!}${process.env.GATSBY_SPACE_ADMINISTRATION_SUB_URI!}${path}`;
  }

  static async getSpaces(token: string, provider?: SpaceProvider): Promise<SupportedSpace[]> {
    const spacesData: SupportedSpaces = provider
      ? await ApiService.request<SupportedSpaces>(this.buildUri('/spaces/' + provider), token)
      : await ApiService.request<SupportedSpaces>(this.buildUri('/spaces'), token);

    if (!features.useTestData) {
      spacesData.spaces.forEach((space: any) => {
        if (space.id === PUBLIC_SPACE) {
          space.config?.llms.forEach((llm: any) => {
            llm.enabled = this.publicSpaceLlmWhitelist.includes(llm.id);
          });
        }
      });
    }

    return spacesData.spaces;
  }

  static async getSpaceRawDetails(spaceId: string, token: string): Promise<GeneratedSupportedSpaceDTO> {
    return ApiService.request<GeneratedSupportedSpaceDTO>(this.buildUri(`/spaces/detail/${spaceId}`), token);
  }

  static async getSpaceDetails(lang: string, spaceId: string, token: string): Promise<SpaceDTO> {
    return this.mapSpaceConfig(
      await ApiService.request<RawSpaceDTO>(this.buildUri(`/spaces/detail/${spaceId}`), token),
      lang,
    );
  }

  static updateSpace(token: string, data: SpaceDraftDTO): Promise<SpaceDraftApiDTO> {
    return ApiService.request(this.buildUri('/spaces'), token, {
      method: 'PUT',
      json: data,
      raw: true,
    });
  }

  static async deleteSpace(token: string, spaceId: string): Promise<void> {
    return ApiService.request(this.buildUri(`/spaces/detail/${spaceId}`), token, {
      method: 'DELETE',
      raw: true,
    });
  }

  static mapSpaceConfig(space: RawSpaceDTO, lang: string): SpaceDTO {
    return {
      ...space,
      keywords: space.keywords?.map(
        (keyword: TranslatedString) => keyword[lang] ?? keyword[i18nConfig.defaultLanguage],
      ),
      samplePrompts: space.samplePrompts?.map((prompt: RawPrompt) => ({
        title: prompt.title[lang] ?? prompt.title[i18nConfig.defaultLanguage],
        prompt: prompt.prompt[lang] ?? prompt.prompt[i18nConfig.defaultLanguage],
      })),
    } as SpaceDTO;
  }

  static uploadSource(spaceId: string, token: string, source: { data?: Blob; fileName?: string; url?: string }) {
    const body = new FormData();
    Object.entries(source).forEach(([key, value]) => body.append(key, value));

    return ApiService.request(this.buildUri(`/sources/${spaceId}`), token, {
      method: 'POST',
      body,
      raw: true,
    });
  }

  static createDraftSpace(token: string, data: SpaceDraftDTO): Promise<SpaceDraftApiDTO> {
    return ApiService.request(this.buildUri('/drafts'), token, {
      method: 'POST',
      json: data,
    });
  }

  static draftSpaceCommand(token: string, spaceId: string, command: 'SUBMIT'): Promise<SpaceDTO> {
    return ApiService.request(this.buildUri(`/drafts/${spaceId}`), token, {
      method: 'POST',
      json: { command },
    });
  }

  static deleteDraftSpace(token: string, spaceId: string) {
    return ApiService.request(this.buildUri(`/drafts/${spaceId}`), token, {
      method: 'DELETE',
      raw: true,
    });
  }

  static getSources(token: string, spaceId: string): Promise<FileDTO[]> {
    return ApiService.request(this.buildUri(`/sources/${spaceId}`), token);
  }

  static deleteSource(token: string, spaceId: string, id: string): Promise<void> {
    return ApiService.request(this.buildUri(`/sources/${spaceId}/${id}`), token, {
      method: 'DELETE',
      raw: true,
    });
  }

  static performAccessRequest(token: string, data: AccessRequestActionDTO) {
    return ApiService.request(this.buildUri('/access-requests/action'), token, {
      method: 'POST',
      json: data,
      raw: true,
    });
  }
}
