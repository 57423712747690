import React, { ReactNode } from 'react';
import { AuthProvider } from './src/context/auth';
import { GuidelinesAcceptedContextProvider } from './src/context/guidelinesAcceptedContext';
import { LocationProvider } from '@reach/router';
import { ThemeProvider } from '@dssf/component-library';
import { URLProvider } from './src/context/url';
import { SpaceConfigProvider } from './src/context/spaceConfig';
import { GeoblockingProvider } from './src/context/geoblocking';

export function wrapRootElement({ element }: { element: ReactNode }) {
  return (
    <ThemeProvider>
      <LocationProvider>
        <URLProvider>
          <AuthProvider>
            <GeoblockingProvider>
              <GuidelinesAcceptedContextProvider>
                <SpaceConfigProvider>{element}</SpaceConfigProvider>
              </GuidelinesAcceptedContextProvider>
            </GeoblockingProvider>
          </AuthProvider>
        </URLProvider>
      </LocationProvider>
    </ThemeProvider>
  );
}
