import { useAuth } from '../context/auth';
import useSWR from 'swr';
import { UserService } from '../services/userService';
import { UserDetails } from '../types/user';

export function useUserDetails(): UserDetails {
  const { token } = useAuth();
  const { data } = useSWR<UserDetails>(token && 'userDetails', async () => UserService.getDetails(token), {
    revalidateIfStale: false,
  });
  if (data) {
    return data;
  }
  return {
    displayName: '',
    givenName: '',
    surname: '',
    costCenter: '',
    department: '',
    country: '',
    mail: '',
    id: '',
    userPrincipalName: '',
  };
}
