import { useTranslation } from '@dssf/component-library';
import { ConfigDialogPage } from '../components/ConfigDialog/ConfigDialog';
import { LLMProvider } from '../types/llm';

export interface LLMModelData {
  id: string;
  title: string;
  description: string;
  provider?: LLMProvider;
  subscribeButton?: string;
  upgradePage?: ConfigDialogPage;
  manageButton?: string;
  managePage?: ConfigDialogPage;
}

export function useLLMModelData(): { llmModelData: { [key: string]: LLMModelData } } {
  const { t } = useTranslation();

  return {
    llmModelData: {
      GPT3: {
        id: 'GPT3',
        title: t('Hooks.LLMModelData.Models.GPT3.Title'),
        description: t('Hooks.LLMModelData.Models.GPT3.Description'),
        provider: 'Microsoft',
      },
      GPT4O_MINI: {
        id: 'GPT4O_MINI',
        title: t('Hooks.LLMModelData.Models.GPT4OMini.Title'),
        description: t('Hooks.LLMModelData.Models.GPT4OMini.Description'),
        provider: 'Microsoft',
      },
      GPT4: {
        id: 'GPT4',
        title: t('Hooks.LLMModelData.Models.GPT4.Title'),
        description: t('Hooks.LLMModelData.Models.GPT4.Description'),
        subscribeButton: t('Hooks.LLMModelData.Models.GPT4.SubscribeButton'),
        upgradePage: 'createSubscription',
        manageButton: t('Hooks.LLMModelData.Models.GPT4.ManageButton'),
        managePage: 'manageSubscription',
        provider: 'Microsoft',
      },
      PALM2: {
        id: 'PALM2',
        title: t('Hooks.LLMModelData.Models.Palm2.Title'),
        description: t('Hooks.LLMModelData.Models.Palm2.Description'),
        provider: 'Google',
      },
      GEMINI: {
        id: 'GEMINI',
        title: t('Hooks.LLMModelData.Models.Gemini.Title'),
        description: t('Hooks.LLMModelData.Models.Gemini.Description'),
        subscribeButton: t('Hooks.LLMModelData.Models.GPT4.SubscribeButton'),
        upgradePage: 'createSubscription',
        manageButton: t('Hooks.LLMModelData.Models.GPT4.ManageButton'),
        managePage: 'manageSubscription',
        provider: 'Google',
      },
      GEMINI_BASIC: {
        id: 'GEMINI_BASIC',
        title: t('Hooks.LLMModelData.Models.GeminiBasic.Title'),
        description: t('Hooks.LLMModelData.Models.GeminiBasic.Description'),
        provider: 'Google',
      },
    },
  };
}
