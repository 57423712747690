export default class LocalStorage {
  static isAvailable(): boolean {
    return typeof localStorage !== "undefined";
  }

  static get<T>(key: string): T | undefined {
    if (this.isAvailable()) {
      const value = localStorage.getItem(key);
      if (value !== null) {
        try {
          return JSON.parse(value);
        } catch (e) {}
      }
    }
    return undefined; // to differentiate from null, which the browser returns when the key does not exist
  }

  static set(key: string, value: any) {
    if (this.isAvailable()) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  static remove(key: string) {
    if (this.isAvailable()) {
      localStorage.removeItem(key);
    }
  }
}
