import React, { createContext, ReactNode, useContext } from 'react';
import useSWR from 'swr';
import LocalStorage from '../services/localStorage';
import ConsentApiService from '../services/consentApiService';
import { useAuth } from '@dssf/component-library/src/contexts/auth';
import { ConsentCategory, UserConsentsDTO } from '../types/consents';

export interface GuidelinesAcceptedContext {
  consents?: AskBoschConsents;
  createConsent: (consent: ConsentCategory) => void;
}

const GuidelinesAcceptedContext = createContext<GuidelinesAcceptedContext>({
  createConsent: async () => {},
});

type AskBoschConsents = { [consent in ConsentCategory]: boolean };

export function useGuidelinesAcceptedContext() {
  return useContext(GuidelinesAcceptedContext);
}

export function GuidelinesAcceptedContextProvider({ children }: { children: ReactNode }) {
  const { token } = useAuth();
  const { data: consents, mutate } = useSWR(token && 'getConsents', () => getConsents(), { revalidateIfStale: false });

  async function getFromLocalStorageOrDefault() {
    const result: { guidelines?: boolean; quiz?: boolean } = {};

    // check if we already have accepted the guidelines and wrote it to local storage. In case we have, we need to
    // write it to the api and delete the values from local storage
    if (LocalStorage.get('guidelinesAccepted') === true) {
      await ConsentApiService.createConsent(
        ConsentApiService.consents.guidelines.category,
        ConsentApiService.consents.guidelines.version,
        token,
      );
      result.guidelines = true;
      LocalStorage.remove('guidelinesAccepted');
    }

    if (LocalStorage.get('quizSuccess') === true) {
      await ConsentApiService.createConsent(
        ConsentApiService.consents.quiz.category,
        ConsentApiService.consents.quiz.version,
        token,
      );
      result.quiz = true;
      LocalStorage.remove('quizSuccess');
    }
    return result;
  }

  const getConsents = async (): Promise<AskBoschConsents> => {
    const result = await getFromLocalStorageOrDefault();

    const consents: UserConsentsDTO = await ConsentApiService.getConsentsDto(token);

    return {
      ...(Object.fromEntries(
        Object.entries(ConsentApiService.consents).map(([key, value]) => [
          key,
          !!consents.contents.find(e => e.category === value.category && e.version === value.version)?.isValid,
        ]),
      ) as AskBoschConsents),
      ...result,
    };
  };

  const createConsent = async (consent: ConsentCategory) => {
    await ConsentApiService.createConsent(
      ConsentApiService.consents[consent].category,
      ConsentApiService.consents[consent].version,
      token,
    );
    await mutate();
  };

  return (
    <GuidelinesAcceptedContext.Provider
      value={{
        consents: consents,
        createConsent,
      }}
    >
      {children}
    </GuidelinesAcceptedContext.Provider>
  );
}
