import useSWR from 'swr';
import { useTranslation } from '@dssf/component-library';
import { useAuth } from '../context/auth';
import { getIndexed } from '../services/getIndexed';
import { useSpaceConfig, useSpaceId } from '../context/spaceConfig';
import { LLMOption, SpaceProvider, SupportedSpace } from '../types/spaceConfig';
import { Indexed } from '../types/indexed';
import { AppFlavourService } from '../services/appFlavourService';
import { SpaceAdministrationService } from '../services/spaceAdministrationService';
import { useMemo } from 'react';
import { LLMModelData, useLLMModelData } from './llmModelData';

export const fallbackLLMId = 'GPT3';
export const fallbackSpaceId = 'PUBLIC';
export const fallbackSpaceProvider = 'PUBLIC';
export const defaultFavouriteSpaces: String[] = [];
export const PUBLIC_SPACE = 'public';
const llmOrder = ['GPT3', 'PALM2', 'GPT4', 'GEMINI'].reverse(); // order of LLMs, order between basic and advanced LLMs does not matter

export interface UseSpacesReturn {
  spaces: SupportedSpace[];
  indexedSpaces: Indexed<SupportedSpace>;
  currentSpace: SupportedSpace | null;
  indexedLLMs: Indexed<Indexed<LLMOption>>;
  availableLLMs: { BASIC: (LLMModelData & LLMOption)[]; ADVANCED: (LLMModelData & LLMOption)[] };
  currentSpaceIndexedLLMs: Indexed<LLMOption> | null;
  reloadSpaces: (provider?: SpaceProvider) => Promise<SupportedSpace[] | undefined>;
  isLoading: boolean;
  advancedLLMs: LLMOption[];
  advancedEnabled: boolean;
}

export interface UseSpaceLLMsReturn {
  currentLLM: LLMOption | null;
}

export function useSpaces(): UseSpacesReturn {
  const { lang } = useTranslation();
  const { token } = useAuth();
  const { currentSpaceId, handleSpaceUpdate } = useSpaceId();
  const { llmModelData } = useLLMModelData();

  const getSpaces = async (provider?: SpaceProvider) => {
    const result = await SpaceAdministrationService.getSpaces(token, provider);
    handleSpaceUpdate(result);
    return result;
  };

  // due to using hooks doing this inside a loop is not really possible, but number of providers is limited
  const {
    data: publicData,
    isLoading: publicLoading,
    mutate: publicMutate,
  } = useSWR(`PUBLIC-${lang}`, () => getSpaces('PUBLIC'), { revalidateIfStale: false });
  const {
    data: brainData,
    isLoading: brainLoading,
    mutate: brainMutate,
  } = useSWR(AppFlavourService.isBeta && `BRAIN-${lang}`, () => getSpaces('BRAIN'), {
    revalidateIfStale: false,
  });
  const {
    data: ragData,
    isLoading: ragLoading,
    mutate: ragMutate,
  } = useSWR(AppFlavourService.isBeta && `RAG-${lang}`, () => getSpaces('RAG'), {
    revalidateIfStale: false,
  });
  const {
    data: skynetData,
    isLoading: skynetLoading,
    mutate: skynetMutate,
  } = useSWR(AppFlavourService.isBeta && `SKYNET-${lang}`, () => getSpaces('SKYNET'), {
    revalidateIfStale: false,
  });

  const spaces = useMemo(
    () => [...(publicData ?? []), ...(brainData ?? []), ...(ragData ?? []), ...(skynetData ?? [])],
    [publicData, brainData, ragData, skynetData],
  );
  const indexedSpaces = useMemo(() => getIndexed(spaces), [spaces]);
  const indexedLLMs =
    (indexedSpaces &&
      Object.fromEntries(
        Object.entries(indexedSpaces).map(([id, space]) => [id, getIndexed(space.config?.llms ?? [])]),
      )) ??
    null;

  const reloadSpaces = async (provider?: SpaceProvider): Promise<SupportedSpace[] | undefined> => {
    switch (provider) {
      case 'PUBLIC':
        return publicMutate();
      case 'BRAIN':
        return brainMutate();
      case 'RAG':
        return ragMutate();
      case 'SKYNET':
        return skynetMutate();
      default:
        return (await Promise.all([publicMutate(), brainMutate(), ragMutate(), skynetMutate()]))
          .flat()
          .filter(e => e) as SupportedSpace[];
    }
  };

  const currentSpace = (currentSpaceId && indexedSpaces[currentSpaceId]) || null;

  const availableLLMs: { BASIC: (LLMModelData & LLMOption)[]; ADVANCED: (LLMModelData & LLMOption)[] } =
    currentSpace?.config?.llms.reduce(
      (s, e) => {
        if (llmModelData[e.id]) {
          s[(e.subscription?.name as 'ADVANCED') ?? 'BASIC'].push({
            ...llmModelData[e.id],
            ...e,
          });
          s[(e.subscription?.name as 'ADVANCED') ?? 'BASIC'].sort(
            (a, b) => llmOrder.indexOf(b.id) - llmOrder.indexOf(a.id),
          );
        }
        return s;
      },
      { BASIC: [], ADVANCED: [] } as { BASIC: (LLMModelData & LLMOption)[]; ADVANCED: (LLMModelData & LLMOption)[] },
    ) ?? { BASIC: [], ADVANCED: [] };

  const advancedLLMs = currentSpace?.config?.llms.filter(model => model.subscription?.name === 'ADVANCED') ?? [];
  const advancedEnabled = advancedLLMs?.some(model => model.enabled) ?? false;

  return {
    spaces,
    indexedSpaces,
    currentSpace: currentSpace,
    indexedLLMs,
    availableLLMs,
    currentSpaceIndexedLLMs: (currentSpaceId && indexedLLMs[currentSpaceId]) || null,
    isLoading: publicLoading || brainLoading || ragLoading || skynetLoading,
    advancedLLMs,
    advancedEnabled,
    reloadSpaces,
  };
}

export function useSpaceLLMs(): UseSpaceLLMsReturn {
  const { currentSpaceId, currentLLMId } = useSpaceConfig();
  const { indexedLLMs } = useSpaces();

  return {
    currentLLM: (currentLLMId && indexedLLMs?.[currentSpaceId ?? '']?.[currentLLMId]) || null,
  };
}
